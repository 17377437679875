import { store } from "@/services/store";
import axios from "axios";

import { RST_EVENT, RST_FIELD } from "@/LocalDB";
import { v4 as uuid } from "uuid";
import SynchroHelper from "@/services/helpers/synchroHelper";
import { RST_COMPETITOR_COMPULSORY_NOTE, RST_COMPETITOR_NOTES, RST_EVENT_COMPULSORY, RST_JUDGE_EVENT_COMPULSORY_NOTE, RST_JUDGE_EVENT_NOTES } from "@/LocalDB";
import { RST_BALLET_NOTE, RST_COMPULSORY_NOTE, RST_EVENT_COMPETITOR, /*RST_EVENT_COMPETITOR,*/ RST_ROUTINE_NOTE } from "../../LocalDB";
import { EventExecutionStepEnum } from '@/Constants';


const EventsConfigurationHelper = {
  VERBOSE : false,

    missingRequirementsBeforeRun(event){
      var ret = [];
      var nbMissing = 0;

      ///// REVOIR CETTE GESTION DES PRE-REQUIS POUR SE BASER SUR UNE OU PLUSIEURS TABLES DE PARAMETRAGE ////
      var eventRule = {
        chiefJudgeIsRequiredToCompeteEvent: true,
        minJudgesRequiredToCompeteEvent: event.COMPETITION.scope == 'region' ? 1 : (event.COMPETITION.scope == 'nation' ? 3 : 5),
        maxJudgesAuthorizedToCompeteEvent: null,
        minArtJudgesRequiredToCompeteEvent: 0,
        minTechJudgesRequiredToCompeteEvent: 0,
        minFieldDirectorsRequiredToCompeteEvent: 1,
        maxFieldDirectorsAuthorizedToCompeteEvent: 1,
        minScorersRequiredToCompeteEvent: 0,
        numberOfCompulsories: event.REF_EVENT.hasCompulsories ? (event.level == 'expert' ? 3 : 2) : 0,
      }
      if(eventRule.minJudgesRequiredToCompeteEvent > 0)
      {
        nbMissing = eventRule.minJudgesRequiredToCompeteEvent - event.JUDGES.filter(item => !item.isShadow ).length;
        if(nbMissing > 0)
          ret.push((nbMissing > 1)
                      ? "Il manque " + nbMissing + " juges titulaires"
                      : "Il manque un juge titulaire");
      }
      
      if(eventRule.minTechJudgesRequiredToCompeteEvent > 0)
      {
        nbMissing = eventRule.minTechJudgesRequiredToCompeteEvent - event.TECH_JUDGES.filter(item => !item.isShadow ).length;
        if(nbMissing > 0)
          ret.push((nbMissing > 1)
                    ? "Il manque " + nbMissing + " juges techniques titulaires"
                    : "Il manque un juge technique titulaire");
      }
      if(eventRule.minArtJudgesRequiredToCompeteEvent > 0)
      {
        nbMissing = eventRule.minArtJudgesRequiredToCompeteEvent - event.ART_JUDGES.filter(item => !item.isShadow ).length;
        if(nbMissing > 0)
          ret.push((nbMissing > 1)
                    ? "Il manque " + nbMissing + " juges artistiques titulaires"
                    : "Il manque un juge artistique titulaire");
      }

      if(eventRule.chiefJudgeIsRequiredToCompeteEvent == true && (event.chiefJudge_staff_id == null || event.chiefJudge_staff_id == '' ))
      {
        ret.push("Le chef juge d'épreuve n'a pas été choisi");
      }

      if(eventRule.minFieldDirectorsRequiredToCompeteEvent > 0)
      {
        nbMissing = eventRule.minFieldDirectorsRequiredToCompeteEvent - event.FIELD_DIRECTORS.filter(item => !item.isShadow ).length;
        if(nbMissing > 0)
          ret.push((nbMissing > 1)
                    ? "Il manque " + nbMissing + " directeurs de terrain titulaires"
                    : "Il manque un directeur de terrain titulaire");
      }
  
      if(eventRule.maxFieldDirectorsAuthorizedToCompeteEvent != null && eventRule.maxFieldDirectorsAuthorizedToCompeteEvent > 0)
      {
        nbMissing = event.FIELD_DIRECTORS.filter(item => !item.isShadow).length - eventRule.maxFieldDirectorsAuthorizedToCompeteEvent;
        if(nbMissing > 0)
          ret.push( "Trop de directeurs de terrain titulaires ont été sélectionnés (" + nbMissing + " en trop. " + (eventRule.maxFieldDirectorsAuthorizedToCompeteEvent > 1 ? "Seuls " + eventRule.maxFieldDirectorsAuthorizedToCompeteEvent + " DT sont attendus)" : "Un seul DT est attendu)"));
      }

     if(eventRule.minScorersRequiredToCompeteEvent > 0)
      {
        nbMissing = eventRule.minScorersRequiredToCompeteEvent - event.SCORERS.filter(item => !item.isShadow ).length;
        if(nbMissing > 0)
          ret.push((nbMissing > 1)
                    ? "Il manque " + nbMissing + " scoreurs titulaires"
                    : "Il manque un scoreur titulaire");
      }

      if(event.REF_EVENT.hasCompulsories) {
        nbMissing = eventRule.numberOfCompulsories - event.COMPULSORIES.length;
        if(nbMissing > 0)
          ret.push("La sélection de figures imposées n'est pas complète. Il en manque " + nbMissing);
        else if (nbMissing < 0)
          ret.push((eventRule.numberOfCompulsories > 1)
              ? "Seules " + eventRule.numberOfCompulsories + " figures imposées peuvent être choisies. Il y en a " + nbMissing + " de trop"
              : "Une seule figure imposée doit être choisie. Il y en a " + nbMissing + " de trop");
      }

      if(event.COMPETITORS.length == 0)
      {
        ret.push("Il n'y a aucun compétiteur d'inscrit dans cette catégorie");
      } else if (event.EFFECTIVE_COMPETITORS.length == 0){
        ret.push((event.COMPETITORS.length > 1)
                    ? 'Tous les compétiteurs inscrits dans cette catégorie sont soit absents soit "forfait"'
                    : 'Le seul compétiteur inscrit dans cette catégorie est soit absent soit "forfait"');
      }
      return ret;
    },

    warningsBeforeRun(event)
    {
      var ret = [];
      var nbMissing = 0;

      var rule = event.CATEGORY.RULE;
      if(rule.minJudgesRequiredToSkipWorseNote > 0)
      {
        nbMissing = rule.minJudgesRequiredToSkipWorseNote - event.JUDGES.filter(item => !item.isShadow ).length;
        if(nbMissing > 0)
          ret.push(((nbMissing > 1)
                      ? "Il manque " + nbMissing + " juges titulaires"
                      : "Il manque un juge titulaire")
                      + " pour pouvoir appliquer la règle de retrait de la plus mauvaise note");
      }
      if(rule.minJudgesRequiredToSlkipBestNote > 0)
      {
        nbMissing = rule.minJudgesRequiredToSlkipBestNote - event.JUDGES.filter(item => !item.isShadow ).length;
        if(nbMissing > 0)
          ret.push(((nbMissing > 1)
                      ? "Il manque " + nbMissing + " juges titulaires"
                      : "Il manque un juge titulaire")
                      + " pour pouvoir appliquer la règle de retrait de la note la plus forte");
      }
      return ret;
    },

    async ensureEventExistsAsync(competition_id, round_number, level_code, category_code, event_code){
      if(this.VERBOSE) console.log('ensureEventExistsAsync - params', competition_id, round_number, level_code, category_code, event_code);
      var existing = RST_EVENT.query()
                        .where("competition_id", competition_id)
                        .where("round_number", round_number)
                        .where("level", level_code)
                        .where("category", category_code)
                        .where("event", event_code)
                        .first();
      var result = (existing && existing != null);
      if(this.VERBOSE) console.log('ensureEventExistsAsync - existing', existing);
      if(!existing || existing == null)
      {
        var defaultField = RST_FIELD.query().where("competition_id", competition_id).where("isMain", true).first();
        var data = {
          id: uuid(),
          competition_id : competition_id,
          round_number: round_number,
          level: level_code,
          category: category_code,
          event: event_code,
          field_number: defaultField.field_number,
          field_stage_in: defaultField.stage_in,
          field_stage_out: defaultField.stage_out,
        };
        if(this.VERBOSE) console.log('ensureEventExistsAsync', data);
        RST_EVENT.insertOrUpdate({ data: data });
        existing = RST_EVENT.find(data.id);
        var operationId = SynchroHelper.LogOperation("INSERT", "RST_EVENT", null, data);
        result = await SynchroHelper.TryToSynchronizeAsync([operationId]);
      }
      return {
        success: result,
        event: existing
      };
    },

    // Remise à zéro de tous les scores saisies au titre de l'événement défini par les différents paramètre.
    // Remise à false de son flag "isStarted" )
    razEvent(competition_id, round_number, level_code, category_code, event_code){
      // On commence par récupérer l'événement en question.
      var rstEvent = this.ensureEventExists(competition_id, round_number, level_code, category_code, event_code);

      // on efface les notes données pour chaque figure imposée (si nécessaire)
      RST_JUDGE_EVENT_COMPULSORY_NOTE.query()
        .where("competition_id", competition_id)
        .where("round_number", round_number)
        .where("level", level_code)
        .where("category", category_code)
        .where("event", event_code)
        .get()
        .forEach(note => {
            RST_JUDGE_EVENT_COMPULSORY_NOTE.delete(note.id);
            SynchroHelper.LogOperation("DELETE", "RST_JUDGE_EVENT_COMPULSORY_NOTE", note.id, null);
        });

      // Puis on passe en revue toutes les notes définies pour la catégorie
      RST_JUDGE_EVENT_NOTES.query()
          .where("competition_id", competition_id)
          .where("round_number", round_number)
          .where("level", level_code)
          .where("category", category_code)
          .where("event", event_code)
          .get()
          .forEach(note => {
              RST_JUDGE_EVENT_NOTES.delete(note.id);
              SynchroHelper.LogOperation("DELETE", "RST_JUDGE_EVENT_NOTES", note.id, null);
          });

      if(rstEvent.EVENT.hasCompulsories)
      {
          RST_COMPETITOR_COMPULSORY_NOTE.query()
          .where("competition_id", competition_id)
          .where("round_number", round_number)
          .where("level", level_code)
          .where("category", category_code)
          .get()
          .forEach(note => {
              var change = { noteEntry: null, noteValue: null, rank: null, federal_rank: null, isCompleted: false };
              RST_COMPETITOR_COMPULSORY_NOTE.update({ where: note.id, data: change});
              SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_COMPULSORY_NOTE", note.id, change);
          });
      }

      RST_COMPETITOR_NOTES.query()
          .where("competition_id", competition_id)
          .where("round_number", round_number)
          .where("level", level_code)
          .where("category", category_code)
          .get()
          .forEach(note => {

            if(rstEvent.EVENT.hasCompulsories){
                var changeCompulsories = {
                  compulsories_total: null,
                  compulsories_rank: null,
                  compulsories_federal_rank: null,
                  compulsories_isCompleted: false,
                  precision_total: null,
                  precision_total_rank: null,
                  precision_total_federal_rank: null,
                  precision_isCompleted: false,
                  overall: null,
                  overall_rank: null,
                  overall_federal_rank: null,
                  isCompleted: false,
                };
                RST_COMPETITOR_COMPULSORY_NOTE.update({ where: note.id, data: changeCompulsories});
                SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", note.id, changeCompulsories, "RESET_COMPULSORIES");
            }

            // On remet ensuite à zéro les données liées à la routine technique si l'événement gère la routine et que cela s'avère nécessaire pour la note en cours
            if(rstEvent.EVENT.hasRoutine){
                var changeRoutine = {
                  routine_execution: null,
                  routine_execution_rank: null,
                  routine_execution_federal_rank: null,
                  routine_content: null,
                  routine_content_rank: null,
                  routine_content_federal_rank: null,
                  routine_total: null,
                  routine_total_rank: null,
                  routine_total_federal_rank: null,
                  routine_isCompleted: false,
                  precision_total: null,
                  precision_total_rank: null,
                  precision_total_federal_rank: null,
                  precision_isCompleted: false,
                  overall: null,
                  overall_rank: null,
                  overall_federal_rank: null,
                  isCompleted: false,
                };
                RST_COMPETITOR_COMPULSORY_NOTE.update({ where: note.id, data: changeRoutine});
                SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", note.id, changeRoutine, "RESET_ROUTINE");
            }

            // Et on fait la même chose pour les notes du ballet
            if(rstEvent.EVENT.hasBallet)
            {
                var changeBallet = {
                  ballet_execution: null,
                  ballet_execution_rank: null,
                  ballet_execution_federal_rank: null,
                  ballet_choreo: null,
                  ballet_choreo_rank: null,
                  ballet_choreo_federal_rank: null,
                  ballet_total: null,
                  ballet_total_rank: null,
                  ballet_total_federal_rank: null,
                  ballet_isCompleted: false,
                  overall: null,
                  overall_rank: null,
                  overall_federal_rank: null,
                  isCompleted: false,
                };
                RST_COMPETITOR_COMPULSORY_NOTE.update({ where: note.id, data: changeBallet});
                SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_NOTES", note.id, changeBallet, "RESET_BALLET");
            }

          });

      var eventChanges = {
        current_competitor_id: null,
        isCompleted: false,
        isBriefingStarted: false,
        current_step: null,
        current_compulsory_id: null,
      };
      RST_EVENT.update({ where: rstEvent.id, data: eventChanges});
      SynchroHelper.LogOperation("UPDATE", "RST_EVENT", rstEvent.id, eventChanges, "INIT_EVENT");

      eventChanges = {
        isBriefingStarted: false,
        isBriefingCompleted: false,
        isStarted: false,
        isCompleted: false,
        isShowingStepSummary: false
      };
      RST_EVENT.update({ where: rstEvent.id, data: eventChanges});
      SynchroHelper.LogOperation("UPDATE", "RST_EVENT", rstEvent.id, eventChanges, "RESET_EVENT_FLAGS");

      SynchroHelper.TryToSynchronize();
    },

        // Remise à zéro de tous les scores saisies au titre de l'événement défini par les différents paramètre.
    // Remise à false de son flag "isStarted" )
    async razEventAsync(competition_id, round_number, level_code, category_code, event_code){
      var operationIds = [];

      var EVENT = RST_EVENT.query()
                  .where("competition_id", competition_id)
                  .where("round_number", round_number)
                  .where("level", level_code)
                  .where("category", category_code)
                  .where("event", event_code)
                  .first();

      var evtUpd = {
        isBriefingStarted: false,
        isBriefingCompleted: false,
        isStarted: false,
        isCompleted: false,
        current_step: null,
        current_compulsory_id: null,
        current_competitor_id: EVENT.EFFECTIVE_COMPETITORS[0].competitor_id,
        isShowingStepSummary: false,
      };

      var competitorsCount = EVENT.EFFECTIVE_COMPETITORS.length;
      var federalCompetitorsCount = EVENT._EFFECTIVE_COMPETITORS.where(c => c.isOpen == false).get().length;
      
      if(EVENT.EVENT.hasCompulsories){
        evtUpd.current_step = EventExecutionStepEnum.Compulsories;
        evtUpd.current_compulsory_id = EVENT.COMPULSORIES[0].compulsory_id;
        RST_COMPULSORY_NOTE.update({
          where: (note)=>{
            return note.competition_id == competition_id
              && note.round_number == round_number
              && note.level == level_code
              && note.category == category_code
              && note.event == event_code
          },
          data: (note)=>{
            note.isAverage = false;
            note.noteEntry = null;
            note.noteValue = null;
            note.rank = competitorsCount + 1;
            note.federal_rank = federalCompetitorsCount + 1;
            note.penalties = null;
          }
        });        
        var razCompulsoryChange = {
          competition_id: competition_id,
          round_number: round_number,
          level: level_code,
          category: category_code,
          event: event_code,
          rank: competitorsCount + 1,
          federal_rank: federalCompetitorsCount + 1,
        };
        operationIds.push(SynchroHelper.LogOperation("MASS_OPERATION", "RST_COMPULSORY_NOTE", null, razCompulsoryChange, 'RAZ_EVENT_NOTES'));
      }
      
      if(EVENT.EVENT.hasRoutine){
        if(evtUpd.current_step == null) evtUpd.current_step == EventExecutionStepEnum.Routine;
        RST_ROUTINE_NOTE.update({
          where: (note) =>{
            return note.competition_id == competition_id
              && note.round_number == round_number
              && note.level == level_code
              && note.category == category_code
              && note.event == event_code
          },
          data: (note) => {
            note.note = null;
            note.execution = null;
            note.content = null;
            note.rank = competitorsCount + 1;
            note.execution_rank = competitorsCount + 1;
            note.content_rank = competitorsCount + 1;
            note.federal_rank = competitorsCount + 1;
            note.execution_federal_rank = competitorsCount + 1;
            note.content_federal_rank = federalCompetitorsCount + 1;
            note.penalties = null;
          }
        });
        var razRoutineChange = {
          competition_id: competition_id,
          round_number: round_number,
          level: level_code,
          category: category_code,
          event: event_code,
          rank: competitorsCount + 1,
          federal_rank: federalCompetitorsCount + 1,
        };
        operationIds.push(SynchroHelper.LogOperation("MASS_OPERATION", "RST_ROUTINE_NOTE", null, razRoutineChange, 'RAZ_EVENT_NOTES'));
      }

      if(EVENT.EVENT.hasBallet){
        if(evtUpd.current_step == null) evtUpd.current_step == EventExecutionStepEnum.Ballet;
        RST_BALLET_NOTE.update({
          where: (note) =>{
            return note.competition_id == competition_id
              && note.round_number == round_number
              && note.level == level_code
              && note.category == category_code
              && note.event == event_code
          },
          data: (note) => {
            note.note = null;
            note.execution = null;
            note.choreo = null;
            note.rank = competitorsCount + 1;
            note.execution_rank = competitorsCount + 1;
            note.choreo_rank = competitorsCount + 1;
            note.federal_rank = competitorsCount + 1;
            note.execution_federal_rank = competitorsCount + 1;
            note.choreo_federal_rank = federalCompetitorsCount + 1;
            note.penalties = null;
          }
        });
        var razBalletChange = {
          competition_id: competition_id,
          round_number: round_number,
          level: level_code,
          category: category_code,
          event: event_code,
          rank: competitorsCount + 1,
          federal_rank: federalCompetitorsCount + 1,
        };
        operationIds.push(SynchroHelper.LogOperation("MASS_OPERATION", "RST_BALLET_NOTE", null, razBalletChange, 'RAZ_EVENT_NOTES'));
      }

      RST_EVENT.update({ where: EVENT.id, data: evtUpd});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT", EVENT.id, evtUpd, 'INIT_EVENT'));

      return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    },

    async changeEventChiefJudgeAsync(competitionId, round_number, level, category, event, staff_member_id)
    {
      var found = RST_EVENT.query().where("competition_id", competitionId).where('round_number', round_number).where('level', level).where('category', category).where('event', event).first();
      if(found != null){
        var dataChanges = { 
          chiefJudge_staff_id: staff_member_id,
        };
        if(this.VERBOSE) console.log(staff_member_id);
        if(this.VERBOSE) console.log(dataChanges);
        if(this.VERBOSE) console.log(found.id);
        RST_EVENT.update({ where: found.id, data: dataChanges });
        var operationId = SynchroHelper.LogOperation("UPDATE", "RST_EVENT", found.id, dataChanges, "CHANGE_CHIEF_JUDGE");
        return await SynchroHelper.TryToSynchronizeAsync([operationId]);
      }
    },

    async selectCompulsories(competition_id, level_code, category_code, event_code, round_number, compulsory_ids){
      if(this.VERBOSE) console.log("selectCompulsories => compulsory_ids", compulsory_ids);
      var operationIds = [];
      var old = RST_EVENT_COMPULSORY.query().where("competition_id", competition_id).where("level", level_code).where("category", category_code).where("event", event_code).where("round_number", round_number).get();
      old.forEach(oldone => RST_EVENT_COMPULSORY.delete(oldone.id));
      var deletionCriteria = {
        competition_id: competition_id,
        level: level_code,
        category: category_code,
        event: event_code,
        round_number: round_number,
      };
      operationIds.push(SynchroHelper.LogOperation("MASS_OPERATION", "RST_EVENT_COMPULSORY", null, deletionCriteria, "CLEAR_COMPULSORIES"));
      for(var i = 0; i < compulsory_ids.length; i++)
      {
        var newRecord = {...deletionCriteria,
          id: uuid(), 
          compulsory_id: compulsory_ids[i],
          order: i+1 
        };
        RST_EVENT_COMPULSORY.insertOrUpdate({ data: newRecord });
        operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_EVENT_COMPULSORY", null, newRecord ));
      }
      return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    },
    
    async refreshEventCompulsories(competition_id, level_code, category_code, event_code, round_number){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getEventsCompulsories.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id, round_number: round_number, level: level_code, category: category_code, event: event_code });
        var alreadySync = RST_EVENT_COMPULSORY.query().where('competition_id', competition_id).where("round_number",round_number).where("level", level_code).where("category", category_code).where("event", event_code).get();//.where('isSync', true).get();
        alreadySync.forEach(item => { RST_EVENT_COMPULSORY.delete(item.id)});
        if(this.VERBOSE) console.log('refreshEventCompulsories (alreadySync, response.data)', alreadySync, response.data)
        RST_EVENT_COMPULSORY.insertOrUpdate({ data: response.data.compulsories})
        return true;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshEvents ERROR ', error);
        return false;
      }   
    },

    /**
     * Cette méthode permet de préparer le contexte d'exécution d'une épreuve en préparant tous les enregistrements nécessaires pour recevoir les notes
     * des différents juges pour les différentes composantes et l'épreuve et ce pour l'ensemble des compétiteurs en lisse.
     * @param {String} competition_id - Id de la compétition
     * @param {Number} round_number - Numéro de la manche
     * @param {String} level - Code du niveau de l'épreuve considérée 
     * @param {String} category - Code de la catégorie de l'épreuve considérée 
     * @param {String} event - Code de l'événement considéré
     * @param {Array} competitors - Liste des compétiteurs
     */
    async initEventRun(competition_id, round_number, level, category, event, competitors){
      var operationIds = [];
      // Pour commencer on récupère l'épreuve à initier.
      var EVENT = RST_EVENT.query()
        .where('competition_id', competition_id)
        .where('round_number', round_number)
        .where('level', level)
        .where('category', category)
        .where('event', event)
        .first();
      var evtUpdate = { 
        isBriefingStarted: true,
        isBriefingCompleted: true,
        isStarted: true,
        isCompleted: false,
        current_competitor_id: null,
        current_step: null,
        current_compulsory_id: null,
        isShowingStepSummary: false,
      };
      var firstCompetitor = true;
      if(this.VERBOSE) console.log('EVENT', EVENT);
      var compCount = EVENT.COMPETITION._COMPETITORS.where(cc => cc.level == level && cc.category == category).get().length;
      competitors.forEach(c => {
        var EVENT_COMPETITOR = EVENT._EFFECTIVE_COMPETITORS.where(ec => ec.competitor_id == c.competitor_id).first();
        if(this.VERBOSE) console.log('competitor', c);
        if(this.VERBOSE) console.log('existing competitor', EVENT_COMPETITOR);
        if(EVENT_COMPETITOR == null){
          var compInsert = {
            id: uuid(),
            competition_id: competition_id,
            round_number: round_number,
            level: level,
            category: category,
            competitor_id: c.competitor_id,
            event: event,
            predicted_order: c.predicted_order,
            order: c.order,
            execution_status: 'W',
            isAbsent: c.isAbsent,
            isForfeited: c.isForfeited,
          };
          RST_EVENT_COMPETITOR.insert( {data: compInsert} );
          operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_EVENT_COMPETITOR", null, compInsert, '' ));
        }
        else
        {
          var compUpdate = {
            predicted_order: c.predicted_order,
            order: c.order,
            effective_order: 0,
            execution_status: 'W',
            isAbsent: c.isAbsent,
            isForfeited: c.isForfeited,
          };
          RST_EVENT_COMPETITOR.update({ where: EVENT_COMPETITOR.id, data: compUpdate});
          operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT_COMPETITOR", EVENT_COMPETITOR.id, compUpdate, 'PREPARE_FOR_RUN' ));
        }
      })

      var federalCompCount = EVENT._EFFECTIVE_COMPETITORS.where(c => c.RST_COMPETITOR.isFederal).get().length;

      // On va maintenant boucler sur chaque compétiteur inscrit et présent à l'épreuve pour préparer ce qu'il faut pour sa notation sur l'épreuve
      EVENT._EFFECTIVE_COMPETITORS.orderBy('predicted_order').get().forEach(competitor => {
        if(firstCompetitor){
          evtUpdate.current_competitor_id = competitor.competitor_id;
          firstCompetitor = false;
        }

        //#region Gestion des enregistrements "totaux" pour chaque composante de l'épreuve pour le compétiteur traité
        if(EVENT.EVENT.hasCompulsories)
        {
          evtUpdate.current_step = EventExecutionStepEnum.Compulsories;

          var totalCompulsories = {
            id: uuid(),
            competition_id: competition_id,
            round_number: round_number,
            level: level,
            category: category,
            event: event,
            competitor_id: competitor.competitor_id,
            judge_id: null,
            isShadowNote: false,
            compulsory_number: null,
            compulsory_id: null,
            rank: compCount,
            isFederal: competitor.isFederal,
            federal_rank: federalCompCount,
            type: 'Compulsory Total (no judge)'
          };
          RST_COMPULSORY_NOTE.insert( {data: totalCompulsories} );
          operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_COMPULSORY_NOTE", null, totalCompulsories, 'TOTAL' ));

          EVENT.COMPULSORIES.forEach(compulsory => {
            var totalByCompulsory = {
              id: uuid(),
              competition_id: competition_id,
              round_number: round_number,
              level: level,
              category: category,
              event: event,
              competitor_id: competitor.competitor_id,
              judge_id: null,
              isShadowNote: false,
              compulsory_number: compulsory.order,
              compulsory_id: compulsory.compulsory_id,
              rank: compCount,
              isFederal: competitor.isFederal,
              federal_rank: federalCompCount,
              type: 'Compulsory Total (no judge)'
            };
            RST_COMPULSORY_NOTE.insert( {data: totalByCompulsory} );
            operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_COMPULSORY_NOTE", null, totalByCompulsory, 'TOTAL' ));
          });
        }
        if(EVENT.EVENT.hasRoutine)
        {
            if(evtUpdate.current_step == null) evtUpdate.current_step = EventExecutionStepEnum.Routine;

            //#region Total note Routine
            var totalRoutines = {
              id: uuid(),
              competition_id: competition_id,
              round_number: round_number,
              level: level,
              category: category,
              event: event,
              competitor_id: competitor.competitor_id,
              judge_id: null,
              isShadowNote: false,
              rank: compCount,
              isFederal: competitor.isFederal,
              federal_rank: federalCompCount,
            };
            RST_ROUTINE_NOTE.insert( {data: totalRoutines} );
            operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_ROUTINE_NOTE", null, totalRoutines, 'TOTAL' ));
        }
        if(EVENT.EVENT.hasBallet)
        {
          if(evtUpdate.current_step == null) evtUpdate.current_step = EventExecutionStepEnum.Ballet;

          var totalBallet = {
            id: uuid(),
            competition_id: competition_id,
            round_number: round_number,
            level: level,
            category: category,
            event: event,
            competitor_id: competitor.competitor_id,
            judge_id: null,
            isShadowNote: false,
            rank: compCount,
            isFederal: competitor.isFederal,
            federal_rank: federalCompCount,
          };
          RST_BALLET_NOTE.insert( {data:totalBallet} );
          operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_BALLET_NOTE", null, totalBallet, 'TOTAL' ));
        }
        //#endregion Gestion des enregistrements "totaux" pour chaque composante de l'épreuve pour le compétiteur traité

        // Il faut également boucler sur chaque juge prévu pour l'épreuve (qu'il soit ombre ou non)
        EVENT.JUDGES.forEach(judge => {
          // Pour terminer, si nécessaire, on va également boucler sur les figures de précisions définies pour l'épreuve.
          if(EVENT.EVENT.hasCompulsories)
          {
            var judgeTotalCompulsories = {
              id: uuid(),
              competition_id: competition_id,
              round_number: round_number,
              level: level,
              category: category,
              event: event,
              competitor_id: competitor.competitor_id,
              judge_id: judge.staff_id,
              isShadowNote: judge.isShadow,
              compulsory_number: null,
              compulsory_id: null,
              rank: compCount,
              isFederal: competitor.isFederal,
              federal_rank: federalCompCount,
              type: "Compulsories total for a given judge"
            };
            RST_COMPULSORY_NOTE.insert( {data:judgeTotalCompulsories} );
            operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_COMPULSORY_NOTE", null, judgeTotalCompulsories, 'JUDGE' ));

            EVENT.COMPULSORIES.forEach(compulsory => {
              if(evtUpdate.current_compulsory_id == null) evtUpdate.current_compulsory_id = compulsory.compulsory_id;

              var judgeCompulsoryNote = {
                id: uuid(),
                competition_id: competition_id,
                round_number: round_number,
                level: level,
                category: category,
                event: event,
                competitor_id: competitor.competitor_id,
                judge_id: judge.staff_id,
                compulsory_number: compulsory.order,
                compulsory_id: compulsory.compulsory_id,
                isShadowNote: judge.isShadow,
                rank: compCount,
                isFederal: competitor.isFederal,
                federal_rank: federalCompCount,
                type: "Judge note for compulsory"
              };
              RST_COMPULSORY_NOTE.insert( {data:judgeCompulsoryNote} );
              operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_COMPULSORY_NOTE", null, judgeCompulsoryNote, 'JUDGE' ));
            });
          }
          if(EVENT.EVENT.hasRoutine){
            //#region judge routine note
            var judgeRoutineNote = {
              id: uuid(),
              competition_id: competition_id,
              round_number: round_number,
              level: level,
              category: category,
              event: event,
              competitor_id: competitor.competitor_id,
              judge_id: judge.staff_id,
              isShadowNote: judge.isShadow,
              rank: compCount,
              isFederal: competitor.isFederal,
              federal_rank: federalCompCount,
            };
            RST_ROUTINE_NOTE.insert( {data:judgeRoutineNote} );
            operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_ROUTINE_NOTE", null, judgeRoutineNote ));
            //#endregion judge routine note
          }
          if(EVENT.EVENT.hasBallet){
            //#region judge Ballet note
            var judgeBalletNote = {
              id: uuid(),
              competition_id: competition_id,
              round_number: round_number,
              level: level,
              category: category,
              event: event,
              competitor_id: competitor.competitor_id,
              judge_id: judge.staff_id,
              isShadowNote: judge.isShadow,
              rank: compCount,
              isFederal: competitor.isFederal,
              federal_rank: federalCompCount,
            };
            RST_BALLET_NOTE.insert( {data:judgeBalletNote} );
            operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_BALLET_NOTE", null, judgeBalletNote , 'TOTAL'));
            //#endregion judge Ballet note
          }
        });
      });
      RST_EVENT.update({ where: EVENT.id, data: evtUpdate});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT", EVENT.id, evtUpdate, 'INIT_EVENT' ));

      return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    },

    async getAllDataForNotationAsync(competition_id, round_number, level_code, category_code, event_code){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getAllEventsDataForNotation.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id, round_number: round_number, level: level_code, category: category_code, event: event_code });
        //if(this.VERBOSE) console.log('getAllDataForNotationAsync', response);
       
        if(response.data){
          var alreadySync = RST_EVENT_COMPULSORY.query().where('competition_id', competition_id).where("round_number",round_number).where("level", level_code).where("category", category_code).where("event", event_code).get();//.where('isSync', true).get();
          alreadySync.forEach(item => { RST_EVENT_COMPULSORY.delete(item.id)});
          if(this.VERBOSE) console.log('refreshEventCompulsories (alreadySync, response.data)', alreadySync, response.data)
          RST_EVENT_COMPULSORY.insertOrUpdate({ data: response.data.compulsories});

          alreadySync = RST_EVENT_COMPETITOR.query().where('competition_id', competition_id).where("round_number",round_number).where("level", level_code).where("category", category_code).where("event", event_code).get();
          alreadySync.forEach(item => { RST_EVENT_COMPETITOR.delete(item.id)});
          RST_EVENT_COMPETITOR.insertOrUpdate({ data: response.data.competitors});

          alreadySync = RST_COMPULSORY_NOTE.query().where('competition_id', competition_id).where("round_number",round_number).where("level", level_code).where("category", category_code).where("event", event_code).get();
          alreadySync.forEach(item => { RST_COMPULSORY_NOTE.delete(item.id)});
          RST_COMPULSORY_NOTE.insertOrUpdate({ data: response.data.compulsory_notes});

          alreadySync = RST_ROUTINE_NOTE.query().where('competition_id', competition_id).where("round_number",round_number).where("level", level_code).where("category", category_code).where("event", event_code).get();
          alreadySync.forEach(item => { RST_ROUTINE_NOTE.delete(item.id)});
          RST_ROUTINE_NOTE.insertOrUpdate({ data: response.data.routine_notes});

          alreadySync = RST_BALLET_NOTE.query().where('competition_id', competition_id).where("round_number",round_number).where("level", level_code).where("category", category_code).where("event", event_code).get();
          alreadySync.forEach(item => { RST_BALLET_NOTE.delete(item.id)});
          RST_BALLET_NOTE.insertOrUpdate({ data: response.data.ballet_notes});

          return true;
        }
      }
      catch(error){
        //console.log('refreshEvents ERROR ', error);
        return false;
      }   
    }
}

export default EventsConfigurationHelper;
